import { ICONS, IMAGES } from "../../../assets/assets";

export const UserAnalysis = () => {
  return (
    <div className="how-it-works-user-analysis">
      <div className="how-it-works-user-analysis-heading">
        Clients' Emotion Monitoring with Session Summary
      </div>
      <div className="how-it-works-user-analysis-text">
        This helps you monitor the effectiveness of your treatment plans and
        make necessary adjustments to better support your clients' emotional
        well-being. Access valuable insights into your clients' progress and
        make data-driven decisions to provide the best possible care.
      </div>
      <div className="how-it-works-user-analysis-graphs">
        {/* <div className="how-it-works-user-analysis-graph-1">
          <img src={IMAGES.trigger_graph} alt="trigger-graph" />
          <div>
            <img src={ICONS.bulb} alt="bulb-icon" className="bulb-icon"></img>
            <div>
              There is inconsistency between their verbal and nonverbal
              communication which may be indicative of a conflict or discordance
              between their internal emotional state and external expression.
            </div>
          </div>
        </div>
        <div className="how-it-works-user-analysis-graph-2">
          <div className="how-it-works-user-analysis-graph-2-1">
            <img src={IMAGES.abcd_graph} alt="trigger-graph" />
            <div className="how-it-works-user-analysis-graph-2-1-component">
              <div className="how-it-works-user-analysis-graph-2-1-componen-head">
                <img
                  src={ICONS.bulb}
                  alt="bulb-icon"
                  className="bulb-icon"
                ></img>
                <div>While Mahima is accepting</div>
              </div>
              <div className="how-it-works-user-analysis-graph-2-1-componen-body">
                the need for therapy and making progress in self-improvement,
                she is struggling with social connection and needs more support
                in discovering effective coping mechanisms.
              </div>
            </div>
          </div>
          <div className="how-it-works-user-analysis-graph-2-2">
            <div className="how-it-works-user-analysis-graph-2-2-heading">
              Emotional Mapping by the Hour
            </div>
            <img src={IMAGES.emotion_heatmap} alt="trigger-graph" />
          </div>
        </div> */}
        <img src={IMAGES.clients_ai_analysis} alt="clients-ai-analysis" />
      </div>
    </div>
  );
};
