import { IMAGES, ICONS } from "../../../assets/assets";

export const UserStory = ({ width }) => {
  return (
    <div className="how-it-works-user-story">
      <div className="how-it-works-user-story-heading">
        Client & Therapist {width > 1165 ? <br /> : ""}
        to share files
      </div>
      <img src={IMAGES.clients_files} alt="client-files" />
      {/* <div className="how-it-works-user-story-text">
        Mindly's platform allows for easy and secure communication, ensuring
        privacy and confidentiality for your clients.
      </div>
      <div className="how-it-works-user-story-concern">
        <div className="how-it-works-user-story-concern-id">#Concern_Id</div>
        <div className="how-it-works-user-story-concern-text">
          A 28-year-old Indian graphic designer seeks help for mental health
          symptoms, such as anxiety and low mood, to address concerns around
          self-esteem and anxiety reduction through mindfulness techniques with
          a focus on short and long-term goals.
        </div>
      </div>
      <div className="how-it-works-user-story-data-entry">
        <div className="how-it-works-user-story-data-entry-image">
          <div className="border-dot"></div>
          <div className="border-vertical-line"></div>
        </div>
        <div className="how-it-works-user-story-data-entry-component">
          <div className="how-it-works-user-story-data-entry-component-heading">
            About You
          </div>
          <ul>
            <li className="how-it-works-user-story-data-entry-component-text">
              I am <span className="highlighted-text">28</span> and my cultural
              background is <span className="highlighted-text">Indian</span>
            </li>
            <li className="how-it-works-user-story-data-entry-component-text">
              Seeking help for{" "}
              <span className="highlighted-text">mental health symptoms</span>
            </li>
            <li className="how-it-works-user-story-data-entry-component-text">
              Would describe myself as{" "}
              <span className="highlighted-text">
                emphatetic, introvered and creative
              </span>
            </li>
            <li className="how-it-works-user-story-data-entry-component-text">
              Currently{" "}
              <span className="highlighted-text">
                working as a graphic designer
              </span>
            </li>
          </ul>
        </div>
      </div> */}
    </div>
  );
};
