import { ICONS } from "../../../assets/assets";

export const HomeNotify = ({
  width,
  isSent,
  email,
  setEmail,
  handlerStoreAPI,
}) => {
  return (
    <div className="home-notify">
      {isSent ? (
        <div className="home-notify-success-wrapper">
          <div className="home-notify-success-message">
            We will notify you once we launch this platform!
          </div>
          <img
            src={ICONS.green_tick}
            alt="green-tick"
            className="home-notify-success-icon"
          />
        </div>
      ) : (
        <div className="home-notify-wrapper">
          <input
            type="text"
            placeholder="Your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></input>
          <div
            className="home-notify-on-launch-button"
            onClick={handlerStoreAPI}
          >
            {width > 750 ? "Notify on Launch" : "Notify Me"}
          </div>
        </div>
      )}
    </div>
  );
};
