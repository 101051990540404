import React, { useRef, useEffect, useState } from "react";
import { useOnScreen, useWindowDimensions } from "../../utils/customHooks";
import "./Product.css";
import { storeEmailAPI } from "../../api/storeEmailAPI";
import { ProductComponent } from "./components/ProductComponent";

const Product = ({ getComponentName }) => {
  const productRef = useRef(null);
  const isVisible = useOnScreen(productRef);

  if (isVisible) {
    getComponentName("product");
  }

  const [email, setEmail] = useState("");
  const [isSent, setIsSent] = useState(false);

  const { height, width } = useWindowDimensions();

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handlerStoreAPI = () => {
    if (validateEmail(email)) {
      storeEmailAPI(email)
        .then(() => {
          setIsSent("true");
          setEmail("");
        })
        .catch((e) => {
          alert("Something went wrong!");
        });
    } else {
      alert("Check your email!");
    }
  };

  useEffect(() => {
    if (isSent) {
      const intervalCall = setInterval(() => {
        setIsSent(false);
      }, 2000);
      return () => {
        // clean up
        clearInterval(intervalCall);
      };
    }
  }, [isSent]);

  return (
    <div className="product" id="#product" ref={productRef}>
      <div className="product-wrapper">
        <div className="product-heading">
          Data Management is easy with Mindly
        </div>
        <ProductComponent
          width={width}
          isSent={isSent}
          email={email}
          setEmail={setEmail}
          handlerStoreAPI={handlerStoreAPI}
        />
      </div>
    </div>
  );
};

export default Product;
