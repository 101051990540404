import axios from "axios";

export const storeEmailAPI = async (emailId) => {
  const baseURL = "https://4fk29fatma.execute-api.ap-northeast-1.amazonaws.com";
  const path = "/mindly/form";

  const body = {
    email_id: emailId,
  };
  const response = await axios.post(`${baseURL + path}`, body);

  return response;
};
