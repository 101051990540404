import { ICONS } from "../../../assets/assets";

export const HomeText = ({ goToSeeHowItWorks }) => {
  return (
    <div className="home-text">
      <div>
        Mindly is a therapist success solution that enhances your ability to
        provide evidence-based, personalised care and track progress more
        easily, while also improving communication with clients and ensuring
        confidentiality.
      </div>
      <div className="home-text-link" onClick={goToSeeHowItWorks}>
        See how it works <img src={ICONS.arrow} alt="arrow"></img>
      </div>
    </div>
  );
};
