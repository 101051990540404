import React from "react";
import { ICONS } from "../../../assets/assets";

export const NavigationBarList = ({ activeComponent, handleClickScroll }) => {
  const request_info_link = "https://forms.gle/oQbXGEBseW6m5zvk8";
  const medium_link = "https://medium.com/@imbesideyouinc";

  return (
    <div className="navbar-contents">
      <div className="navbar-contents-list">
        <div
          className={
            activeComponent === "home"
              ? "navbar-contents-item highlighted"
              : "navbar-contents-item"
          }
          onClick={() => handleClickScroll("#home")}
        >
          Home
        </div>
        <div
          className={
            activeComponent === "services"
              ? "navbar-contents-item highlighted"
              : "navbar-contents-item"
          }
          onClick={() => handleClickScroll("#services")}
        >
          Services
        </div>
        <div
          className={
            activeComponent === "product"
              ? "navbar-contents-item highlighted"
              : "navbar-contents-item"
          }
          onClick={() => handleClickScroll("#product")}
        >
          Product
        </div>
        <div
          className={
            activeComponent === "how-it-works"
              ? "navbar-contents-item highlighted"
              : "navbar-contents-item"
          }
          onClick={() => handleClickScroll("#how-it-works")}
        >
          How It Works
        </div>
        <div
          className={
            activeComponent === "participate"
              ? "navbar-contents-item highlighted"
              : "navbar-contents-item"
          }
          onClick={() => handleClickScroll("#participate")}
        >
          Participate
        </div>
        <div
          className="navbar-contents-item"
          onClick={() => window.open(medium_link, "_blank").focus()}
        >
          Blogs{" "}
          <img
            className="external_link_nav_list "
            src={ICONS.external_link}
            alt="external-link"
          ></img>
        </div>
      </div>
      <div
        className="navbar-contents-button"
        onClick={() => window.open(request_info_link, "_blank").focus()}
      >
        Request Info
      </div>
    </div>
  );
};
