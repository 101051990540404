import React from "react";
import { IMAGES } from "../../../assets/assets";

const ServicesCommunication = () => {
  return (
    <div className="services-communication-component">
      <img
        className="services-communication-component-image"
        src={IMAGES.secure_communication}
        alt="secure-communication"
      ></img>
      <div className="services-communication-component-heading">
        Secure Communication
      </div>
      <div className="services-communication-component-text">
        Secure communication for therapists and clients while ensuring easy
        access to care without compromising privacy.
      </div>
    </div>
  );
};

export default ServicesCommunication;
