import { IMAGES, VIDEOS } from "../../../assets/assets";

export const ParticipateSurvey = () => {
  const therapy_suvery_url =
    "https://docs.google.com/forms/d/e/1FAIpQLSfbhKMKOZZ0icwzUhG6WcTqGmrtqVhzVAbl6AoGFrJGwJV2qg/viewform?ts=643cd261";
  const client_suvery_url =
    "https://docs.google.com/forms/d/e/1FAIpQLSeeEl6TT6mDz70m_1nBiwVMpYvL8_2yzOZ_xslnXYsOjxRwpg/viewform?ts=643cd276";

  return (
    <div className="participate-survey">
      {/* <div className="participate-survey-image">
        <img src={IMAGES.survey} alt="survey" />
      </div> */}
      <div className="participate-survey-video">
        <div className="participate-survey-video-player">
          <div className="participate-survey-video-heading">
            What do professionals say about Mindly?
          </div>
          <video
            src={VIDEOS.iby_summary_video}
            type="video/mp4"
            controls
            controlslist="nofullscreen nodownload noremoteplayback noplaybackrate foobar"
          />
        </div>
      </div>
      <div className="participate-survey-text">
        <div className="participate-survey-text-heading">
          Help Us Improve
          <br /> Mindly!
        </div>
        <div className="participate-survey-text-data">
          We at Mindly are always looking to improve our platform and services
          to better meet the needs of our users. If you're interested in sharing
          your thoughts and experiences with us, we would greatly appreciate
          your feedback through our survey form. Your responses will help us
          understand how we can make Mindly even better for therapists and their
          clients. Thank you for your time and support!
        </div>
        <div className="participate-survey-text-button-group">
          <div
            className="participate-survey-text-button-therapist"
            onClick={() => window.open(therapy_suvery_url, "_blank").focus()}
          >
            Therapist Survey
          </div>
          <div
            className="participate-survey-text-button-client"
            onClick={() => window.open(client_suvery_url, "_blank").focus()}
          >
            Client Survey
          </div>
        </div>
      </div>
    </div>
  );
};
