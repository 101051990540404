import logo from "./icons/logo.svg";
import bulb from "./icons/bulb.svg";
import linkedin from "./icons/linkedin.svg";
import linkedin_white from "./icons/linkedin_white.svg";
import twitter from "./icons/twitter.svg";
import twitter_white from "./icons/twitter_white.svg";
import arrow from "./icons/arrow.svg";
import green_tick from "./icons/green-tick.svg";
import navigation_drawer from "./icons/navigation_drawer.svg";
import external_link from "./icons/link.png";

import emotion_cradle from "./images/1-1.svg";
import mindly_banner from "./images/1-2.svg";
import client_mangagement from "./images/2-1.svg";
import secure_communication from "./images/2-2.svg";
import progress_tracking from "./images/2-3.svg";
import mindly_dashboard from "./images/3-1.svg";
import trigger_graph from "./images/4-1-archive.svg";
import emotion_heatmap from "./images/4-2-archive.svg";
import abcd_graph from "./images/4-3-archive.svg";
import clients_files from "./images/4-1.svg";
import clients_ai_analysis from "./images/4-2.svg";
import survey from "./images/5-1-archive.svg";
import iby_logo from "./images/iby-logo.svg";
import iby_white_logo from "./images/iby-white_logo.svg";

import iby_summary_video from "./videos/iby_summary_video.mp4";

export const ICONS = {
  logo: logo,
  bulb: bulb,
  linkedin: linkedin,
  twitter: twitter,
  arrow: arrow,
  green_tick: green_tick,
  navigation_drawer: navigation_drawer,
  linkedin_white: linkedin_white,
  twitter_white: twitter_white,
  external_link: external_link,
};

export const IMAGES = {
  emotion_cradle: emotion_cradle,
  mindly_banner: mindly_banner,
  client_mangagement: client_mangagement,
  secure_communication: secure_communication,
  progress_tracking: progress_tracking,
  mindly_dashboard: mindly_dashboard,
  trigger_graph: trigger_graph,
  abcd_graph: abcd_graph,
  emotion_heatmap: emotion_heatmap,
  survey: survey,
  iby_logo: iby_logo,
  iby_white_logo: iby_white_logo,
  clients_files: clients_files,
  clients_ai_analysis: clients_ai_analysis,
};

export const VIDEOS = {
  iby_summary_video: iby_summary_video,
};
