import { IMAGES, ICONS } from "../../../assets/assets";

export const ProductComponent = ({
  width,
  isSent,
  email,
  setEmail,
  handlerStoreAPI,
}) => {
  return (
    <div className="product-component">
      <img
        className="product-component-image"
        src={IMAGES.mindly_dashboard}
        alt="mindly-dashboard"
      />
      <div className="product-component-text">
        <div className="product-component-text-heading">
          ENHANCE YOUR PRACTICE WITH MINDLY!
        </div>
        <div className="product-component-text-data">
          Mindly uses Ai to analyse client data and generate accurate emotion
          data, along with a comprehensive trigger map. This valuable
          information serves as the foundation for creating a robust client
          database, complete with personalised treatment plans. Mental health
          professionals can leverage this database to access real-time insights,
          enabling them to make informed decisions and provide timely
          interventions.
        </div>
        <div className="product-component-notify">
          {isSent ? (
            <div className="product-component-notify-success-wrapper">
              <div className="product-component-notify-success-message">
                We will notify you once we launch this platform!
              </div>
              <img
                src={ICONS.green_tick}
                alt="green-tick"
                className="product-component-notify-success-icon"
              />
            </div>
          ) : (
            <div className="product-component-notify-wrapper">
              <input
                type="text"
                placeholder="Your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></input>
              <div
                className="product-component-notify-on-launch-button"
                onClick={handlerStoreAPI}
              >
                {width > 450 ? "Notify on Launch" : "Notify Me"}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
