import React, { useRef, useEffect, useState } from "react";
import { useOnScreen, useWindowDimensions } from "../../utils/customHooks";
import "./Services.css";
import ServicesClient from "./components/ServicesClient";
import ServicesCommunication from "./components/ServicesCommunication";
import ServicesProgress from "./components/ServicesProgress";

const Services = ({ getComponentName }) => {
  const servicesRef = useRef(null);
  const isVisible = useOnScreen(servicesRef);

  if (isVisible) {
    getComponentName("services");
  }

  const { height, width } = useWindowDimensions();

  const [serviceId, setServiceId] = useState(0);

  useEffect(() => {
    const intervalCall = setInterval(() => {
      let i = serviceId + 1;
      setServiceId(i % 3);
    }, 3000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, [serviceId]);

  const handleLearnMore = () => {
    const element = document.getElementById("#how-it-works");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="services" id="#services" ref={servicesRef}>
      <div className="services-heading">Services We Provide</div>
      {width > 965 ? (
        <div className="services-components">
          <ServicesClient handleLearnMore={handleLearnMore} />
          <ServicesCommunication />
          <ServicesProgress />
        </div>
      ) : (
        <div className="services-components">
          {serviceId === 0 ? (
            <ServicesClient handleLearnMore={handleLearnMore} />
          ) : serviceId === 1 ? (
            <ServicesCommunication />
          ) : (
            <ServicesProgress />
          )}
        </div>
      )}
      {width > 965 ? (
        <div></div>
      ) : (
        <div className="home-banner-component-radio-buttons">
          <span
            className={serviceId === 0 ? "highlighted-dot" : "dot"}
            onClick={() => setServiceId(0)}
          ></span>
          <span
            className={serviceId === 1 ? "highlighted-dot" : "dot"}
            onClick={() => setServiceId(1)}
          ></span>
          <span
            className={serviceId === 2 ? "highlighted-dot" : "dot"}
            onClick={() => setServiceId(2)}
          ></span>
        </div>
      )}
    </div>
  );
};

export default Services;
