import { ICONS, IMAGES } from "../../../assets/assets";

export const ParticipateFooter = () => {
  const company_link = "https://www.imbesideyou.com/corporate";
  const privacy_link = "https://www.imbesideyou.com/privacy";
  const terms_link = "https://www.imbesideyou.com/terms";

  const linkedin_link = "https://www.linkedin.com/company/imbesideyou/";
  const twitter_link = "https://twitter.com/BesideIm";

  return (
    <div className="participate-footer">
      <img
        className="participate-footer-logo"
        src={IMAGES.iby_logo}
        alt="iby-logo"
      ></img>
      <div className="participate-footer-text">
        <div className="participate-footer-text-data">
          Your therapy practice with IBY Mindly's all-in-one
          <br /> solution for personalized client care.
        </div>
        <div className="participate-footer-text-links">
          <div
            className="participate-footer-text-link"
            onClick={() => window.open(company_link, "_blank").focus()}
          >
            Company
          </div>
          <div
            className="participate-footer-text-link"
            onClick={() => window.open(privacy_link, "_blank").focus()}
          >
            Privacy Policy
          </div>
          <div
            className="participate-footer-text-link"
            onClick={() => window.open(terms_link, "_blank").focus()}
          >
            Terms and Conditions
          </div>
        </div>
      </div>
      <div className="participate-footer-links">
        <div className="participate-footer-social-links">
          <img
            className="participate-footer-social-link"
            src={ICONS.linkedin}
            alt="linkedin-icon"
            onClick={() => window.open(linkedin_link, "_blank").focus()}
          ></img>
          <img
            className="participate-footer-social-link"
            src={ICONS.twitter}
            alt="twitter-icon"
            onClick={() => window.open(twitter_link, "_blank").focus()}
          ></img>
        </div>
        <div>
          <div className="participate-footer-copyright">
            ©︎ 2023 I'mbesideyou Inc.
          </div>
        </div>
      </div>
    </div>
  );
};
