import React from "react";
import { IMAGES } from "../../../assets/assets";

const ServicesClient = ({ handleLearnMore }) => {
  return (
    <div className="services-client-component">
      <div className="services-client-component-heading">Client Management</div>
      <div className="services-client-component-text">
        Mindly offers a comprehensive platform for managing client information,
        treatment plans, and progress tracking. With our intuitive interface,
        therapists can easily stay up-to-date on their clients' needs and adjust
        their treatment plans accordingly.
      </div>
      <div
        className="services-client-component-button"
        onClick={handleLearnMore}
      >
        Learn More
      </div>
      <img
        className="services-client-component-image"
        src={IMAGES.client_mangagement}
        alt="client-management"
      />
    </div>
  );
};

export default ServicesClient;
