import React from "react";
import { IMAGES } from "../../../assets/assets";

const ServicesProgress = () => {
  return (
    <div className="services-progress-component">
      <div className="services-progress-component-heading">
        Progress Tracking
      </div>
      <div className="services-progress-component-text">
        Track your clients' progress and emotions. Get valuable insights to
        provide the best possible care.
      </div>
      <img
        className="services-progress-component-image"
        src={IMAGES.progress_tracking}
        alt="secure-communication"
      ></img>
    </div>
  );
};

export default ServicesProgress;
