import React from "react";
import { ICONS, IMAGES } from "../../../assets/assets";

export const NavigationDrawer = ({
  activeComponent,
  isVisible,
  setIsVisible,
  handleClickScroll,
}) => {
  const linkedin_link = "https://www.linkedin.com/company/imbesideyou/";
  const twitter_link = "https://twitter.com/BesideIm";
  const company_link = "https://www.imbesideyou.com/corporate";

  const medium_link = "https://medium.com/@imbesideyouinc";

  const request_info_link = "https://forms.gle/oQbXGEBseW6m5zvk8";

  return (
    <div className="navbar-drawer">
      <img
        src={ICONS.navigation_drawer}
        alt="navigation-drawer"
        className="navbar-drawer-icon"
        onClick={() => {
          setIsVisible((o) => !o);
        }}
      ></img>
      <div
        className="navbar-drawer-component"
        style={{
          // right: !isVisible ? "0px" : "-1000px",
          transition: "width .2s ease-in",
          width: !isVisible ? "80vw" : "0px",
        }}
      >
        <div
          className="navbar-contents-list"
          style={{
            display: !isVisible ? "flex" : "none",
          }}
        >
          <div
            className={
              activeComponent === "mobile-home"
                ? "navbar-contents-item highlighted"
                : "navbar-contents-item"
            }
            onClick={() => handleClickScroll("#mobile-home")}
          >
            Home
          </div>
          <div
            className={
              activeComponent === "request-info"
                ? "navbar-contents-item highlighted"
                : "navbar-contents-item"
            }
            onClick={() => handleClickScroll("#request-info")}
          >
            About
          </div>
          <div
            className={
              activeComponent === "services"
                ? "navbar-contents-item highlighted"
                : "navbar-contents-item"
            }
            onClick={() => handleClickScroll("#services")}
          >
            Services
          </div>
          <div
            className={
              activeComponent === "product"
                ? "navbar-contents-item highlighted"
                : "navbar-contents-item"
            }
            onClick={() => handleClickScroll("#product")}
          >
            Product
          </div>
          <div
            className={
              activeComponent === "how-it-works"
                ? "navbar-contents-item highlighted"
                : "navbar-contents-item"
            }
            onClick={() => handleClickScroll("#how-it-works")}
          >
            How It Works
          </div>
          <div
            className={
              activeComponent === "participate"
                ? "navbar-contents-item highlighted"
                : "navbar-contents-item"
            }
            onClick={() => handleClickScroll("#participate")}
          >
            Participate
          </div>
          <div
            className="navbar-contents-item"
            onClick={() => window.open(medium_link, "_blank").focus()}
          >
            Blogs{" "}
            <img
              className="external_link_nav_drawer "
              src={ICONS.external_link}
              alt="external-link"
            ></img>
          </div>
        </div>
        <div
          className="navbar-contents-button"
          style={{ display: !isVisible ? "flex" : "none" }}
          onClick={() => window.open(request_info_link, "_blank").focus()}
        >
          Request Info
        </div>
        <div
          className="navbar-drawer-footer"
          style={{ display: !isVisible ? "flex" : "none" }}
        >
          <img
            src={IMAGES.iby_white_logo}
            alt="iby-logo"
            onClick={() => window.open(company_link, "_blank").focus()}
          ></img>
          <div>
            <img
              src={ICONS.linkedin_white}
              alt="linkedin"
              onClick={() => window.open(linkedin_link, "_blank").focus()}
            />
            <img
              src={ICONS.twitter_white}
              alt="twitter"
              onClick={() => window.open(twitter_link, "_blank").focus()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
