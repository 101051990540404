import { IMAGES } from "../../../assets/assets";

export const HomeEmotionCradle = () => {
  return (
    <div className="home-emotion-cradle">
      <div className="home-emotion-cradle-wrapper">
        <img src={IMAGES.emotion_cradle} alt="emotion-cradle"></img>
        <div className="home-emotion-cradle-text">
          Empowering therapists to create emotional wellness through
          personalised care.
        </div>
      </div>
    </div>
  );
};
