import React, { useEffect, useState } from "react";
import "./App.css";
import Header from "./screens/header/Header";
import Home from "./screens/home/Home";
import Services from "./screens/services/Services";
import Product from "./screens/prodcut/Product";
import HowItWorks from "./screens/howItWorks/HowItWorks";
import Participate from "./screens/participate/Participate";
import { useWindowDimensions } from "./utils/customHooks";
import MobileHome from "./screens/home/MobileHome";
import RequestInfo from "./screens/home/RequestInfo";
import Blog from "./screens/blog/Blog";
import CookieConsent  from "react-cookie-consent";


function App() {
  const [activeComponent, setActiveComponent] = useState("home");

  const getComponentName = (name) => {
    setActiveComponent(name);
  };

  const { height, width } = useWindowDimensions();

  useEffect(() => {
    const doc = document.querySelector(".components");
    if (doc) {
      const calculatedHeight = height - 100;
      doc.style.setProperty("height", `${calculatedHeight}px`);
    }
  }, [height]);

  return (
    <div className="App">
      <Header activeComponent={activeComponent} />
      <div className="components">
        {width > 965 ? (
          <Home getComponentName={getComponentName} />
        ) : (
          <MobileHome getComponentName={getComponentName} />
        )}
        {width > 965 ? (
          <div></div>
        ) : (
          <RequestInfo getComponentName={getComponentName} />
        )}
        <Services getComponentName={getComponentName} />
        <Product getComponentName={getComponentName} />
        <HowItWorks getComponentName={getComponentName} />
        <Participate getComponentName={getComponentName} />
        {/* <Blog getComponentName={getComponentName} /> */}
        <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
      </div>
    </div>
  );
}

export default App;
