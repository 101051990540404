import React, { useState } from "react";
import "./Navbar.css";
import { ICONS } from "../../assets/assets";
import { NavigationBarList } from "./components/NavBarList";
import { NavigationDrawer } from "./components/NavigationDrawer";

const Navbar = ({ activeComponent }) => {
  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const [isVisible, setIsVisible] = useState(true);

  return (
    <div className="navbar">
      <div className="navbar-heading">
        <img src={ICONS.logo} alt="mindly-logo"></img>
      </div>
      <NavigationBarList
        activeComponent={activeComponent}
        handleClickScroll={handleClickScroll}
      />
      <NavigationDrawer
        activeComponent={activeComponent}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        handleClickScroll={handleClickScroll}
      />
    </div>
  );
};

export default Navbar;
