import React, { useRef, useState, useEffect } from "react";
import { HomeBanner } from "./components/HomeBanner";
import { HomeHeading } from "./components/HomeHeading";
import { HomeNotify } from "./components/HomeNotify";

import { useOnScreen, useWindowDimensions } from "../../utils/customHooks";
import { storeEmailAPI } from "../../api/storeEmailAPI";

import "./Home.css";

const MobileHome = ({ getComponentName }) => {
  const mobileHomeRef = useRef(null);

  const { height, width } = useWindowDimensions();

  const isVisible = useOnScreen(mobileHomeRef);

  const [textId, setTextId] = useState(0);

  useEffect(() => {
    const intervalCall = setInterval(() => {
      let i = textId + 1;
      setTextId(i % 5);
    }, 3000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, [textId]);

  if (isVisible) {
    getComponentName("mobile-home");
  }

  const [email, setEmail] = useState("");
  const [isSent, setIsSent] = useState(false);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const handlerStoreAPI = () => {
    if (validateEmail(email)) {
      storeEmailAPI(email)
        .then(() => {
          setIsSent("true");
          setEmail("");
        })
        .catch((e) => {
          alert("Something went wrong!");
        });
    } else {
      alert("Check your email!");
    }
  };

  useEffect(() => {
    if (isSent) {
      const intervalCall = setInterval(() => {
        setIsSent(false);
      }, 2000);
      return () => {
        // clean up
        clearInterval(intervalCall);
      };
    }
  }, [isSent]);

  return (
    <div className="home" id="#mobile-home" ref={mobileHomeRef}>
      <HomeHeading width={width} />
      <HomeBanner textId={textId} />
      <HomeNotify
        width={width}
        isSent={isSent}
        email={email}
        setEmail={setEmail}
        handlerStoreAPI={handlerStoreAPI}
      />
    </div>
  );
};

export default MobileHome;
