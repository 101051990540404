import React from "react";
import Navbar from "../../components/navbar/Navbar";
import "./Header.css";

const Header = ({ activeComponent }) => {
  return (
    <div className="header">
      <Navbar activeComponent={activeComponent} />
    </div>
  );
};

export default Header;
