import React, { useRef } from "react";
import { useOnScreen } from "../../utils/customHooks";
import "./Participate.css";
import { ParticipateFooter } from "./components/ParticipateFooter";
import { ParticipateSurvey } from "./components/ParticipateSurvey";

const Participate = ({ getComponentName }) => {
  const participateRef = useRef(null);
  const isVisible = useOnScreen(participateRef);

  if (isVisible) {
    getComponentName("participate");
  }


  return (
    <div className="participate" id="#participate" ref={participateRef}>
      <div className="participate-wrapper">
        <div className="participate-components">
          <ParticipateSurvey />
          <ParticipateFooter />
        </div>
      </div>
    </div>
  );
};

export default Participate;
