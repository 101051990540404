import React, { useRef, useState, useEffect } from "react";
import { useOnScreen, useWindowDimensions } from "../../utils/customHooks";
import { UserAnalysis } from "./components/UserAnalysis";
import { UserStory } from "./components/UserStory";
import "./HowItWorks.css";

const HowItWorks = ({ getComponentName }) => {
  const howItWorksRef = useRef(null);
  const isVisible = useOnScreen(howItWorksRef);

  if (isVisible) {
    getComponentName("how-it-works");
  }

  const { height, width } = useWindowDimensions();

  const [userStoryId, setUserStoryId] = useState(0);

  useEffect(() => {
    const intervalCall = setInterval(() => {
      let i = userStoryId + 1;
      setUserStoryId(i % 2);
    }, 3000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, [userStoryId]);

  return (
    <div className="how-it-works" id="#how-it-works" ref={howItWorksRef}>
      <div className="how-it-works-wrapper">
        <div className="how-it-works-heading">How It Works</div>
        {width > 1165 ? (
          <div className="how-it-works-components">
            <UserStory width={width} />
            <UserAnalysis />
          </div>
        ) : (
          <div className="how-it-works-components">
            {userStoryId === 0 ? <UserStory width={width} /> : <UserAnalysis />}
          </div>
        )}
        {width > 1165 ? (
          <div></div>
        ) : (
          <div className="how-it-works-component-radio-buttons">
            <span
              className={userStoryId === 0 ? "highlighted-dot" : "dot"}
              onClick={() => setUserStoryId(0)}
            ></span>
            <span
              className={userStoryId === 1 ? "highlighted-dot" : "dot"}
              onClick={() => setUserStoryId(1)}
            ></span>
          </div>
        )}
      </div>
    </div>
  );
};

export default HowItWorks;
