import { IMAGES } from "../../../assets/assets";

export const HomeBanner = ({ textId }) => {
  return (
    <div className="home-banner">
      <div className="home-banner-data">
        Overwhelmed by the demands of running an OT practice?
        <br />
        <span> Our solution can streamline your workload.</span>
        <div className="home-banner-component">
          <div className="home-banner-component-radio-buttons">
            <span className={textId === 0 ? "highlighted-dot" : "dot"}></span>
            <span className={textId === 1 ? "highlighted-dot" : "dot"}></span>
            <span className={textId === 2 ? "highlighted-dot" : "dot"}></span>
            <span className={textId === 3 ? "highlighted-dot" : "dot"}></span>
            <span className={textId === 4 ? "highlighted-dot" : "dot"}></span>
          </div>
          <div className="home-banner-component-text">
            {textId === 0
              ? "Mindly helps me provide more personalised and effective care to my clients."
              : textId === 1
              ? "With Mindly, I can track my clients' progress more easily and ensure that I am meeting their needs"
              : textId === 2
              ? "Using Mindly's user-friendly interface, I can communicate with my clients more effectively and provide quality care."
              : textId === 3
              ? "Mindly's secure communication platform enables me to provide quality care while maintaining confidentiality."
              : "Mindly's affordability and ease of use enables me to provide quality care to clients of all backgrounds"}
          </div>
        </div>
      </div>
      <div className="home-banner-image">
        <img src={IMAGES.mindly_banner} alt="banner" />
      </div>
    </div>
  );
};
