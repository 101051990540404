import React, { useRef } from "react";
import { HomeText } from "./components/HomeText";
import { HomeEmotionCradle } from "./components/HomeEmotionCradle";

import { useOnScreen } from "../../utils/customHooks";

import "./Home.css";

const RequestInfo = ({ getComponentName }) => {
  const requestInfoRef = useRef(null);

  const isVisible = useOnScreen(requestInfoRef);

  if (isVisible) {
    getComponentName("request-info");
  }

  const goToSeeHowItWorks = () => {
    const element = document.getElementById("#how-it-works");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const request_info_link =
    "https://forms.gle/oQbXGEBseW6m5zvk8";

  return (
    <div className="request-info" id="#request-info" ref={requestInfoRef}>
      <HomeEmotionCradle />
      <HomeText goToSeeHowItWorks={goToSeeHowItWorks} />
      <div
        className="request-info-button"
        onClick={() => window.open(request_info_link, "_blank").focus()}
      >
        Request Info
      </div>
    </div>
  );
};

export default RequestInfo;
